import styled from "styled-components";

export const StyledAboutSection = styled.section`
  min-height: 102vh;
  width: 100%;
  display:flex;
  align-items: center;

  

  @media (max-width: ${({theme}) => theme.mobile}){

    
    flex-direction: column;
    align-items:flex-start;
    padding: 5rem 0rem 5rem 0;

    p{
      
    }
    
    
    h1{
      font-size:3rem;
    }
  
  }
`;

export const StyledImageContainer = styled.div`
  width: 25rem;
  border-radius:50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3.5rem;
  position:relative;
  margin-top: 150px;

  @media (max-width:${({theme}) => theme.mobile}){
    height: 15rem;
    width: 15rem;
    margin-bottom: 4rem;
    margin: 0 auto;
    display: block;
    margin-bottom:100px;
  }
`;

export const StyledImage = styled.img`
  width: 25rem;
  border-radius: 2%;
  position: absolute;
  z-index: 4;
  user-select: none; 
  pointer-events: none;

  @media (max-width: ${({theme}) => theme.mobile}){ 
    width: 15rem;
    margin: 0 auto;
    display: block;
  }
  
`;

export const StyledAboutContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
 

  p{
    font-family: "Poppins", sans-serif;
    font-size: 1.6rem;
    text-align: justify;
    margin-bottom: 1.45rem;
    @media (max-width:${({theme}) => theme.mobile}){
   font-size:1.3rem;
   margin-bottom: 1.45rem;
  }
  }
  @media (max-width: ${({theme}) => theme.mobile}){
  
  
  }

`;

export const StyledHeading = styled.div`
  font-size: 3rem;
  margin-bottom: 20px;
`;









export const Projects = [
    
    
    {
        image: "/assets/images/portfolio/1_Portfolio/Portfolio.png",
        title: "projectTitle2",
        tags: ["HTML", "CSS", "JS", "React"],
        paragraph:"projectParagraph2",
        codeLink: "",
        projectLink: "https://www.gregorydemeur.be",
        projectLinkText: ""
    },
    {
        image: "/assets/images/portfolio/2_Animalice/Animalice.png",
        title: "projectTitle3",
        tags: ["HTML", "CSS", "JS", "React"],
        paragraph:"projectParagraph3",
        codeLink: "",
        projectLink: "https://www.animalice.be",
        projectLinkText: "accederAuSiteWeb"
    },
    {
        image: "/assets/images/portfolio/5_uBento/uBento.PNG",
        title: "projectTitle4",
        tags: ["Full Stack","React", "Node.js", "Firebase", "Tailwind CSS", "API Discord" ],
        paragraph: "projectParagraph4",
        codeLink: "",
        projectLink: "https://ubento-tfe.web.app/",
        projectLinkText: "voirLeProjet",
        
      },
      {
        image: "/assets/images/portfolio/6_gdm-to-do-list/gdm-to-do-list.png",
        title: "projectTitle5",
        tags: ["Full stack", "React", "Typescript", "Java","Spring", "Azure","SQL"],
        paragraph: "projectParagraph5",
        codeLink: "",
        projectLink: "https://www.gregorydemeur.be/gdm-to-do-list",
        projectLinkText: "voirLeProjet"
    }



   
   
]
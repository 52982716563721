import styled, { keyframes } from "styled-components";

const typing = keyframes`
0%{
    width: 0ch;
}
100%{
    width: 35ch;
}
`;


const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;



export const StyledHeroSection = styled.section`
  transition: ${({ theme }) => theme.transition};
  width: 100%;
  height: 102vh;
  background: linear-gradient(
      135deg,
      ${({ theme }) => theme.colors.heroSectionHeaderLinearOneSvg},
      ${({ theme }) => theme.colors.heroSectionHeaderLinearTwoSvg}
    ),
    url(${process.env.PUBLIC_URL + "/assets/images/sections/landing/GDM_Landing.svg"}) center 0px no-repeat;

  background-size: contain;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    background: linear-gradient(
        135deg,
        ${({ theme }) => theme.colors.heroSectionHeaderLinearOneSvg},
        ${({ theme }) => theme.colors.heroSectionHeaderLinearTwoSvg}
      ),
      url(${process.env.PUBLIC_URL + "/assets/images/sections/landing/GDM_Landing_mobile.svg"}) center 50px
        no-repeat;
  }

  
`;

export const StyledHeroContent = styled.div`
  width: 100%;
  max-width: 90rem;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  transition: ${({ theme }) => theme.transition};

  h1 {
    letter-spacing: 1.2rem;
    font-size: 3rem;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    width: 35ch;
    margin: auto;
    animation: ${typing} 1.3s steps(35);
    color: ${({ theme }) => theme.colors.titleGregoryDeMeur};
    
   
    //border-right: 4px solid rgba(255, 255, 255, 1);
  }

  h3 {
    font-size: 4rem;
    margin: 1.5rem 0;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.titleLinearGradientOne} 0%,
      ${({ theme }) => theme.colors.titleLinearGradientTwo} 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: 1.7rem;
    font-weight: 400;
    text-transform: none;
  }

  div {
    margin: 2.5rem 0;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    h1 {
      letter-spacing: 0.5rem;
      font-size: 1.4rem;
      width: 100%;
      margin: 0 auto;

    }
    h3 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
`;

export const StyledArrowDown = styled.a`
  position: absolute;
  bottom: 50px;
  svg {
    font-size: 70px;
    color: ${({ theme }) => theme.colors.arrowDown};
    animation: ${bounce} 2s infinite 2s;

    &:hover {
      color: ${({ theme }) => theme.colors.arrowDownHover};
      transform: ${({ theme }) => theme.transform};
    }
  }
`;
